<template>
    <main class="main">
        <!-- <nav class="breadcrumb-nav border-0 mb-0">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <nuxt-link to="/">Home</nuxt-link>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:;">Pages</a>
                    </li>
                    <li class="breadcrumb-item active">404</li>
                </ol>
            </div>
        </nav> -->

        <div
            class="error-content text-center"
        >
            <div class="container">
                <h1 class="error-title">{{$t('page.notFound.error_title')}}</h1>
                <h1 class="error-title">{{$t('page.notFound.page_not_found')}}</h1>

                <p>{{$t('page.notFound.error_desc')}}</p>
                <!-- <nuxt-link to="/" class="btn btn-outline-primary-2 btn-minwidth-lg">
                    <span>BACK TO HOMEPAGE</span>
                    <i class="icon-long-arrow-right"></i>
                </nuxt-link> -->
            </div>
        </div>
    </main>
</template>
<script>
import axios from "axios";
export default {
    layout: 'empty',
    //  async asyncData({ params, query, env, $axios, req, store, $t,app: { i18n }}) {
    //     const async_locale = i18n.locale;
    //     if (process.server) {
    //     console.log( "Process Server", req.headers, `http://${req.headers.host}/api/mzapps/merchant`);      
    //     if(params.catalogue_id !== 'null' && params.catalogue_id !== 'undefined' && params.catalogue_id !== '.env') {
    //         var requestURL = `http://${req.headers.host}/api/mzapps/merchant?timestamp=${new Date().getTime()}`;
    //         var settingURL = `http://${req.headers.host}/api/mzapps/settings`;
    //         if(req.headers.orghost) {
    //             requestURL = `https://${req.headers.orghost}/api/mzapps/merchant?timestamp=${new Date().getTime()}`;
    //         settingURL = `https://${req.headers.orghost}/api/mzapps/settings`;
    //         }
    //         var response, settingRes;
            
    //         try {
    //             response = await axios.get(requestURL);
    //             settingRes = await axios.get(settingURL);
    //             console.log("GET camapignRes setting ###", settingRes.data) 
            
    //             return {
    //             merchantDetails: response.data.merchant,
    //             merchantSettings: settingRes.data,
    //             async_locale:async_locale,
                
    //             };
    //         }
    //         catch(e) {
    //             console.log('errorrrrrrr###',e)
    //         //    error({ statusCode: 404, message: 'Post not found' })
    //             return {
    //                 merchantDetails: null,
    //             merchantSettings: null,
    //             async_locale:async_locale,
    //             };
    //         }
    //   }
    //   // else {
    //   //   return {
    //   //     merchantDetails: '',
    //   //     kakao_key: process.env.KAKAO_KEY,
    //   //     event_id: params.event_id,
    //   //     country: params.country
    //   //   };
    //   // }
       
      
    // }
    
    // },
//      head() {
//     return {
//         title: `Catalogue | Customer Signup`,
//         // meta: [
//         // { hid: "robots", name: "robots", content: "noindex" },
//         // {
//         //     name: "viewport",
//         //     content:
//         //     "width:device-width, initial-scale:1.0, maximum-scale:1.0, user-scalable=no",
//         // },

//         // {
//         //     property: "og:image",
//         //   content: `${this.merchantDetails.merchant_logourl}`,
//         //     vmid: "og:image",
//         // },
//         // ],
//         link: [
//           { rel: 'icon', type: 'image/x-icon', href: `${this.merchantDetails.merchant_logourl}` },
//           { rel: "apple-touch-icon", sizes:"128x128", href:`${this.merchantDetails.merchant_logourl}`},
//       ],
//         script: [
//         { src: "/js/jquery.js", async: true },
//         { src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", charset: 'UTF-8', "data-domain-script":"453c50ad-1176-479f-9ffd-82c9deb09e1c"},
//           { src: "https://www.google-analytics.com/analytics.js", async: true },
//           { innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
//             (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
//             m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
//             })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
//             ga('create', 'UA-96639441-1', 'auto')
//             ga('send', 'pageview')
//             ga('create', '${this.merchantSettings.analytic_id}', 'auto', '${this.merchantSettings.analytic_name}');
//             ga('${this.merchantSettings.analytic_name}.send', 'pageview');
//             `, type: 'text/javascript', charset: 'utf-8'},
//         {src: "https://www.googletagmanager.com/gtag/js?id=AW-977747240", async: true},
//         {src: "https://www.googletagmanager.com/gtag/js?id=G-MCJR40CPQE", async: true},
//         { innerHTML: `function OptanonWrapper() { };
//           if("${this.merchantDetails.merchant_id}" !== "8QQH") {
//             window.dataLayer = window.dataLayer || [];
//             function gtag(){dataLayer.push(arguments);}
//             gtag('js', new Date());
//             gtag('config', 'AW-977747240');
//             gtag('config', 'G-MCJR40CPQE');
            
//           }
//          `, type: 'text/javascript', charset: 'utf-8'},
//       ],

      
//     };
//   },
    mounted() {
        if (this.merchantSettings !== undefined) {
            console.log('MerchantSettings###',this.merchantSettings);
        var default_code = this.merchantSettings.default_lang;
                console.log('default_code',default_code);
                if (default_code !== '') {
                    if (default_code === 'ja') {
                        this.async_locale =  'ja' 
                    }
                    if (default_code === 'kr') {
                        this.async_locale =  'kr'
                    }
                    if (default_code === 'th') {
                        this.async_locale = 'th' 
                    }
                    if (default_code === 'id') {
                        this.async_locale = 'id' 
                    }
                    if (default_code === 'zh_Hant') {
                        this.async_locale =  'zh_Hant'
                    }
                    if (default_code === 'zh_Hans') {
                        this.async_locale =  'zh_Hans' 
                    }
                    if (default_code === 'en') {
                        this.async_locale = 'en' 
                    }
                }
                
                var lang_code = this.$store.state.sessionStorage.select_lang;
                if (lang_code !== '') {
                    if (lang_code === 'ja') {
                        this.async_locale =  'ja' 
                    }
                    if (lang_code === 'kr') {
                        this.async_locale =  'kr'
                    }
                    if (lang_code === 'th') {
                        this.async_locale = 'th' 
                    }
                    if (lang_code === 'id') {
                        this.async_locale = 'id' 
                    }
                    if (lang_code === 'zh_Hant') {
                        this.async_locale =  'zh_Hant'
                    }
                    if (lang_code === 'zh_Hans') {
                        this.async_locale =  'zh_Hans' 
                    }
                    if (lang_code === 'en') {
                        this.async_locale = 'en' 
                    }
                }
        }
         
        this.$store.commit('sessionStorage/assignLang', this.async_locale);
        
        console.log('created Lang:',this.$store.state.sessionStorage.lang)
        this.$i18n.locale = this.$store.state.sessionStorage.lang;
        this.$router.push({path: this.$route.path, query: { lang: this.$i18n.locale,catalogue_code:this.catalogue_code }});
    }
};
</script>
<style scoped>
.error-content {
    padding-top: 200px !important;
}
</style>